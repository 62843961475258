
import * as React from "react";
import { Component } from "react";
import StyledContentLoader from 'styled-content-loader'

import PickupDestinationView from "./PickupDestinationView";

export default class OrderView extends Component {


    constructor(props) {
        super(props);

        this.state = {
            order: props.order
          };

    }
    showToast(msg){

            this.props.showToast(msg);
    }


    getStatusColor(status){

        switch(status) {

            case 1:
               return 'green';
            case 11:
               return 'red';
            default:
               return 'red';

        }
    }



    getStatusText(status){

        switch(status) {

            case 1:
               return 'مكتمل';
            case 11:
               return 'ملغي';
            default:
               return 'ملغي';

        }
    }

    render() {
        return (
            <StyledContentLoader
   
            isLoading={this.props.state.isLoadingOrders}
            >

            <div style={{
                width: '90%', height: 260, border: '1px solid lightgray', textAlign: 'right', backgroundColor:'#fff',
                borderRadius:3,
                marginLeft:'5%', marginTop:10
            }}>

          <div style={{marginTop: 12, textAlign:'right', direction:'rtl', marginRight: 15, fontSize: 12, color:'gray'}}>
            # {this.props.order.No}
            </div>
            <div style={{marginTop: 12, textAlign:'right', direction:'rtl'}}>
            <span style={{ marginRight: 15, fontSize: 14, fontWeight:'bold', textAlign:'right'}}>
                {this.props.order.Datetime}
            </span>
            <span style={{marginRight: 100, fontSize: 14, fontWeight: 'bold', color:this.getStatusColor(this.props.order.StatusVal)}}>
                {this.getStatusText(this.props.order.StatusVal)}
            </span>
            </div>
           
            <PickupDestinationView
            pickup={this.props.order.PickupText}
            destination={this.props.order.DestinationText}
            />

            <div style={{marginRight: 15, marginTop: 10, direction:'rtl'}}>

            <div style={{display: 'inline-flex'}}>
            التكلفة  <span style={{fontWeight: 'bold', marginRight: 5}}>{this.props.order.Paid} ₪</span>     

            </div>

            <div style={{display: 'inline-flex', marginRight: 40}}>
            المحصل <span style={{fontWeight: 'bold', marginRight: 5}}>{this.props.order.Price} ₪</span>

            </div>
                
            </div>


            <div style={{marginRight: 15, marginTop: 10}}>
                المسافة المقطوعة <span style={{fontWeight: 'bold'}}>{this.props.order.Distance}</span> كم في مدة <span style={{fontWeight: 'bold'}}>{this.props.order.Duration}</span>
            </div>

                {(this.props.order.ComplainReply == null &&  this.props.order.ComplainText == null) && 
                  <div onClick={()=>{this.props.showTicketView(this.props.order.No, this.props.order.orderId)}} style={{textAlign:'right', marginTop: 15, marginRight:15, color:'#8a0000'}}>
                  طلب مراجعة للرحلة
                 </div>
                }
             
             {(this.props.order.ComplainText != null && this.props.order.ComplainReply == null) && 
                  <div onClick={()=>{this.props.showTicketText(this.props.order.No,this.props.order.ComplainText,this.props.order.ComplainTextTimestamp)}} style={{textAlign:'right', marginTop: 15, marginRight:15, color:'#000caf'}}>
            متابعة مراجعة الرحلة
                </div>
                }

                  {(this.props.order.ComplainText != null && this.props.order.ComplainReply != null) && 
                  <div onClick={()=>{this.props.showTicketReply(this.props.order.No,this.props.order.ComplainText,this.props.order.ComplainTextTimestamp, this.props.order.ComplainReply, this.props.order.ComplainReplyTimestamp)}} style={{textAlign:'right', marginTop: 15, marginRight:15, color:'#008a51'}}>
                        تمت المراجعة            
          
                </div>
                }
            </div>

       </StyledContentLoader>
        )
    }

}