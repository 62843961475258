import * as React from "react";
import { Component } from "react";
import './App.css';
import logo from './assets/wheels_logo.png'
import captain from './assets/captain.png'
import { LatLng, LatLngBounds,divIcon } from "leaflet";
import L from 'leaflet';

import { Map, TileLayer, CircleMarker, Popup, Marker } from "react-leaflet";
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer';
import DriverDetails from "./Components/DriverDetails";
import OrderView from "./Components/OrderView";
import { CarIcon } from "./Components/CarIcon";
import axios from 'axios';
import RotatedMarker from "./Components/RotatedMarker";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import styled, { keyframes } from 'styled-components';
import Rating from "./Components/Rating";

import {whlogo} from "./assets/logo";
import TicketView from "./Components/TicketView";
import HotelIcon from '@material-ui/icons/Hotel';

import { pulse } from 'react-animations';
import TicketTextView from "./Components/TicketTextView";

const pulseAnimation = keyframes`${pulse}`;

const AnimatedDiv = styled.div`
  animation: 3s ${pulseAnimation} infinite;
`;



export default class App extends Component {

  
  
   android = window.Android == undefined? undefined: window.Android;

  constructor(props) {
    super(props);
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let token = params.get('token');



    this.state = {
      BASE_URL: 'http://captains.wheels.ps:3040',
      lat: 51.505,
      lng: -0.09,
      zoom: 16,
      markers: [],
      loading: true,
      captainPosition: new LatLng(32.1,35.278),
      captainRotation: 45,
      token: token,
      resPosition: new LatLng(32.1,35.278),
      resName: '',
      driverName:'',
      driverPhone:'',
      vType:'',
      vNo:'',
      rating: 5,
      driverType: '',
      phase:'loading',
      orderExpired: false,
      orderNo: '',
      orderId: '',
      showTicketView:false,
      showTicketText:false,
      showTicketReply:false,
      ticketText:'',
      ticketReply:'',
      currentDate:'',
      enableNext: false,
      enablePrev:false,
      isLoadingOrders: true,
      sendingTicket: false,
      messages: [{
        "type" : 0,
        "image": logo,
        "text": "--",
        "timestamp": '--'
    }, {
        "type": 1,
        "image": logo,
        "text": "--",
        "timestamp": '--'

    }],
      orders: [
        {

        No:'--',
        Duration: '--',
        StatusVal: 1, Datetime: '--',
        PickupText: '--',DestinationText: '--',
        Price: 0, Paid: 0, DriverName: '--',Distance: 0, Duration: 0, orderId: '--',
        ComplainText: null, ComplainReply: null, ComplainReplyTimestamp: null, ComplainTextTimestamp: null

      },        {

        No:'--',
        Duration: '--',
        StatusVal: 1, Datetime: '--',
        PickupText: '--',DestinationText: '--',
        Price: 0, Paid: 0, DriverName: '--',Distance: 0, Duration: 0, orderId: '--',
        ComplainText: null, ComplainReply: null, ComplainReplyTimestamp: null, ComplainTextTimestamp: null

      },        {

        No:'--',
        Duration: '--',
        StatusVal: 1, Datetime: '--',
        PickupText: '--',DestinationText: '--',
        Price: 0, Paid: 0, DriverName: '--',Distance: 0, Duration: 0, orderId: '--',
        ComplainText: null, ComplainReply: null, ComplainReplyTimestamp: null, ComplainTextTimestamp: null

      }
    ]

  
    };


    this.mapRef = React.createRef();
    this.resMarker = React.createRef();
    this.captainMarker = React.createRef();


  }
  
 close(){


if(this.android != undefined)
    this.android.closeCaptainPortalWebView();
 }

  loadOrders(date,next,prev){


   if(this.android == undefined)
    return;



 this.setState({isLoadingOrders: true},()=>{


  let driverId =window.driverId; //'7395870c-943a-48bf-b1f8-8209bc2de8e6';  // //
  axios.post(this.state.BASE_URL+`/Captains/getTrips`, {
    driverId: driverId,
    currentDate: date, next: next, prev: prev
  })
  .then(res => {

    var data = res.data;


    if(data.success)
    this.setState({
      orders: data.orders,
      currentDate: data.currentDate,
      enableNext: data.enableNext,
      enablePrev: data.enablePrev,
      isLoadingOrders: false,

    },async()=>{

    })

    else {
      this.setState({orders: []})
       if(this.android == undefined) alert("حدث مشكلة في التواصل مع ويلز .. حاول مرة اخرى لاحقاً"); else this.android.showToast("حدث مشكلة في التواصل مع ويلز .. حاول مرة اخرى لاحقاً");
    }

  }).catch((e)=>{

    this.setState({isLoadingOrders: true});

    if(this.android == undefined) alert("حدث مشكلة في التواصل مع ويلز .. حاول مرة اخرى لاحقاً"); else this.android.showToast("حدث مشكلة في التواصل مع ويلز .. حاول مرة اخرى لاحقاً");

  })



 });


  }

 




  componentDidMount(){
    this.loadOrders('',false,false);

    window.eeapp = this;

    if(this.android != undefined)
      this.android.hideBar();

  }


  goback(){

    if(this.state.showTicketText)
    this.setState({showTicketText: false});
    else if(this.state.showTicketView)
    this.setState({showTicketView: false});
    else {
      if(this.android != undefined)
        this.android.closeCaptainPortalWebView(); 
    }
  }

  sendTicket(ticket){
    this.setState({sendingTicket: true},()=>{



      axios.post(this.state.BASE_URL+`/Captains/openTicket`, {
        orderId: this.state.orderId,
        ticket: ticket
       
      })
      .then(res => {
        var data = res.data;
        if(data.success)
        this.hideTicketView();
    
        else {
           if(this.android == undefined) alert("حدث مشكلة في التواصل مع ويلز .. حاول مرة اخرى لاحقاً"); else this.android.showToast("حدث مشكلة في التواصل مع ويلز .. حاول مرة اخرى لاحقاً");
        }
    
      }).catch((e)=>{
    
        this.setState({isLoadingOrders: false});
        if(this.android == undefined) alert("حدث مشكلة في التواصل مع ويلز .. حاول مرة اخرى لاحقاً"); else this.android.showToast("حدث مشكلة في التواصل مع ويلز .. حاول مرة اخرى لاحقاً");

        // if(this.android == undefined) alert("حدث مشكلة في التواصل مع ويلز .. حاول مرة اخرى لاحقاً"); else this.android.showToast(e.message);
      })


    });
  }

  showTicketView(no, orderId){
    this.setState({orderNo: no, orderId: orderId, showTicketView: true});
  }

  hideTicketView(){
    this.setState({orderNo: '', orderId:'', sendingTicket: false, showTicketView: false},()=>{

      this.loadOrders(this.state.currentDate,false,false);

    });
  }

  showTicketText(no,text,datetime){
    this.setState({orderNo: no, ticketText:text,
      messages: [{
        "type" : 0,
        "image": captain,
        "text":text,
        "timestamp": datetime
      }],
      
      showTicketText: true});
  }

  hideTicketText(){
    this.setState({orderNo: '', ticketText:'',  showTicketView: false});
  }


  showTicketReply(no,text,textdate,reply,replydate){
    this.setState({orderNo: no, ticketText:text,
      messages: [{
        "type" : 0,
        "image": captain,
        "text":text,
        "timestamp":textdate
      },{
      "type" : 1,
      "image": logo,
      "text":reply,
      "timestamp": replydate
    }],
      
      showTicketText: true});  }



  render() {
    return (
     <div
     style={{width: '100%', height:'100%', textAlign:'center', paddingTop: 15}}
     >

     <div 
      style={{width: '100%', height:'100%', textAlign:'center',}}
     >
     <span style={{fontSize: 18, fontWeight: 'bold'}}>
     رحلات الكابتن
     </span>
     <span onClick={()=>{this.close()}} style={{fontSize: 14, color: 'red', position: "absolute", left: 20, top: 18}}>
      اغلاق
     </span>
     </div>

     {this.state.orders.map((order,i) => (

        <OrderView
        showToast={(msg)=> this.showToast(msg)}
        key={i}
        order={order}
        state={this.state}
        showTicketView={(no,orderId)=>{this.showTicketView(no,orderId)}}
        showTicketText={(no,complain,date)=>{this.showTicketText(no,complain,date)}}
        showTicketReply={(no,complain,tdate,reply,rdate)=>{this.showTicketReply(no,complain,tdate,reply,rdate)}}
        />

     ))}


      {this.state.orders.length == 0 && 

      
      <div style={{marginTop: 150}}>
        <div>لا يوجد رحلات في التاريخ المحدد</div>
        <HotelIcon style={{fontSize: 100, color: 'gray', marginTop: 30}}/>
        </div>
        
      }

     <div style={{marginTop: 50}}></div>



      <div style={{position:'fixed', bottom:0, width:'100%', height: 50, backgroundColor:'#fff', borderTop: 1, borderTopWidth:1, borderTopColor:'#bfbfbf', borderTopStyle: 'solid'}}>

    


      {this.state.enableNext && 

 <ArrowBackIcon onClick={()=> {this.loadOrders(this.state.currentDate,true,false)}} 
      style={{
        borderRadius: 50, backgroundColor: '#f5f5f5', fontSize: 40, marginTop: 5, marginBottom: -10
      }}
      />
    }

    
{!this.state.enableNext && 

<ArrowBackIcon
     style={{
       borderRadius: 50, backgroundColor: '#f5f5f5', fontSize: 40, color:'lightgray', marginTop: 5, marginBottom: -10
     }}
     />
   }


            <span style={{fontSize: 25, marginRight: 15, marginLeft: 15,}}>
              {this.state.currentDate}
              </span>

{this.state.enablePrev && 

<ArrowForwardIcon onClick={()=> {this.loadOrders(this.state.currentDate,false,true)}} 
style={{
  borderRadius: 50, backgroundColor: '#f5f5f5', fontSize: 40, marginBottom: -10
}}
/>

}

{!this.state.enablePrev && 

<ArrowForwardIcon 
style={{
  borderRadius: 50, backgroundColor: '#f5f5f5', color: 'lightgray', fontSize: 40, marginBottom: -10
}}
/>

}
    
      </div>


      {this.state.showTicketView &&  <TicketView state={this.state} send={(ticket)=>{
      if(ticket == null || ticket.trim() == '')
      return;
      this.sendTicket(ticket)
      
      }} 
      orderNo={this.state.orderNo+'#'} /> } 
      {this.state.showTicketText && 

       <TicketTextView
        hide={()=>{this.hideTicketText()}}
        orderNo={this.state.orderNo+'#'}
        messages={this.state.messages}
  
        />
       
       } 
     </div>
    );
  }
}
