import L from 'leaflet';

const CarIcon = new L.Icon({
    iconUrl: require('../assets/car_top.png'),
   // iconRetinaUrl: require('../assets/car_top.png'),
    iconAnchor: null,
    popupAnchor: null,
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    className: 'leaflet-div-icon'
});

export { CarIcon };