
import * as React from "react";
import { Component } from "react";
import Call from '@material-ui/icons/Call';
import Star from '@material-ui/icons/Star';
import Button from '@material-ui/core/Button';

export default class DriverDetails extends Component {


    constructor(props) {
        super(props);

        this.state = {
            driver: props
          };

    }

    render() {
        return (

            <div
            style={{
                flex: 1,flexDirection:'column',
            position:'absolute', bottom: 0,zIndex: 9999,
            width:'100%',height:150,
            backgroundColor:'white',
            shadowOffset: { width: 15, height: 15 },
            shadowColor: "grey",
            shadowOpacity: 0.8,
            elevation: 15,
            shadowRadius: 20, justifyContent:'center',alignItems:'center',
             marginBottom: 0, 
             borderTopLeftRadius:25,borderTopRightRadius: 25
            }}
            >



        <div style={{
            flex: 1,flexDirection:'column',justifyContent:'center',alignItems:'center', textAlign:'center',
            marginTop:20

        }}>

     <div>
         <span>
         <Star style={{marginTop: 10, fontSize: 14}}/>
         </span>
         <span style={{fontWeight:'600'}}>{this.state.driver.rating}</span>
         <span style={{marginLeft: 20, fontWeight:'600'}}>                 
         {this.state.driver.name}
</span>
         </div>   

         <div>
             <span
             style={{fontSize: 14, fontWeight:'600', paddingRight: 5,paddingLeft: 5, height:22,  border: '1px solid lightgray', borderRadius:3}}
             >                 
             {this.state.driver.vNo}
             </span>

             <span style={{marginLeft: 15,fontWeight:'600'}}>
                 {this.state.driver.vType}
             </span>
         </div>

        <div
        style={{position:'absolute', bottom:0, width:'100%'}}

        >
        <Button onClick={()=>{ window.open("tel:"+this.state.driver.phone) }} variant="outlined" style={{width: '100%', borderRadius: 0, height: 45}}>
        
        <Call style={{ color:'green'}}/>
        <span style={{marginLeft: 10, fontWeight:'600',}}>اتصل بالكابتن</span>
        
        </Button>

        </div>

        </div>

            </div>

        )
    }

}