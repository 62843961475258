
import * as React from "react";
import { Component } from "react";
import Button from '@material-ui/core/Button';
import ReactStars from "react-rating-stars-component";
import axios from 'axios';


export default class Rating extends Component {


    constructor(props) {
        super(props);

        this.state = {
            driver: props.name,
            token: props.token,
            rating: 5,
            comment: '',
            btnDisabled: false,
            btnText: 'ارسال'
          };

    }

    sendRating(){

        this.setState({btnText:'جار الارسال', btnDisabled: true})

        var token = this.state.token;

    if(token == null)
    return;
    axios.post(`http://apis.wheels.ps:64281/wheels/RateOrder`, {
      token: this.state.token,
      rating: this.state.rating,
      comment: this.state.comment
    })
    .then(res => {
      var data = res.data;

      if(data.success)
      window.location.href = "http://www.wheels.ps"
      else if(data.message == "already_rated")
      window.location.href = "http://www.wheels.ps"


    })
    }

    ratingChanged(newRating){
        this.setState({
            rating: newRating
        })


    
      }
    
    
    render() {

       

        return (

            <div
            style={{
                flex: 1,flexDirection:'column',
            position:'absolute', top: 0,zIndex: 999999,
            width:'100%',height:'100%',
            backgroundColor:'white',
            justifyContent:'center',alignItems:'center',
            textAlign: 'center'
           
            }}
            >

            <div 
            style={{marginTop: 100,fontWeight:'bold'}}
            >
            <span>
            ما تقييمك للكابتن {this.state.driver}؟
            </span>


            <div
            style={{
               flex:1, justifyContent:'center',alignItems:'center',
               textAlign:'center', marginTop:40

            }}
            >
            <ReactStars
           
           count={5}
           value={5}
           onChange={(rating)=>{this.ratingChanged(rating)}}
           size={40}
           activeColor="#ffd700"
            />



            <textarea
            placeholder={'اضافة تعليق ...'}
            value={this.state.comment}
            onChange={(evt)=>{this.setState({comment: evt.target.value})}}
            style={{width: '80%', height: 200,direction:'rtl', padding: 20,fontSize:16}}
            ></textarea>


            <Button variant="contained"
            onClick={()=>{this.sendRating()}}
            disabled = {this.state.btnDisabled}
            style={{width: '90%', backgroundColor:'#000', color: '#fff', borderRadius:5}}
            >
                {this.state.btnText}
            </Button>
            </div>



           
                
            </div>

            </div>

        )
    }

}