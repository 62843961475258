
import * as React from "react";
import { Component } from "react";
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import Brightness1Icon from '@material-ui/icons/Brightness1';
import RemoveIcon from '@material-ui/icons/Remove';
import { Button, TextareaAutosize, TextField } from "@material-ui/core";
import MicRecorder from 'mic-recorder-to-mp3';
import AudioRecorderView from "./AudioRecorderView";
import styled, { keyframes } from 'styled-components';

import { slideInUp } from 'react-animations'
const slideInUpAnimation = keyframes`${slideInUp}`;

const AnimatedDiv = styled.div`
  animation: 0.2s ${slideInUpAnimation};
`;

const Mp3Recorder = new MicRecorder({ bitRate: 128 });

export default class TicketView extends Component {


    constructor(props) {
        super(props);
        this.state = {
            text:''
          }
          this.input = React.createRef();

    }
    // componentDidMount(){
    //     navigator.getUserMedia({ audio: true },
    //         () => {
    //           console.log('Permission Granted');
    //           this.setState({ isBlocked: false });
    //         },
    //         () => {
    //           console.log('Permission Denied');
    //           this.setState({ isBlocked: true })
    //         },
    //       );
    // }

    start = () => {
        if (this.state.isBlocked) {
          console.log('Permission Denied');

          alert("denied")
        } else {
          Mp3Recorder
            .start()
            .then(() => {
              this.setState({ isRecording: true });
            }).catch((e) => alert(e) );
        }
      };

      stop = () => {
        Mp3Recorder
          .stop()
          .getMp3()
          .then(([buffer, blob]) => {
            const blobURL = URL.createObjectURL(blob)
            this.setState({ blobURL, isRecording: false });
          }).catch((e) => console.log(e));
      };


    
    render() {



        return (

         <AnimatedDiv style={{position:'fixed', top:0, width:'100%', height: '100%', direction:'rtl', textAlign:'center',backgroundColor: '#fff', zIndex:99999}}>
        <div style={{marginTop: 20, fontSize: 18}}>تفاصيل طلب المراجعة لرحلة رقم {this.props.orderNo}</div>
             <TextareaAutosize
             ref={this.input}
              autoFocus={true}
              style={{width: '83%',height: 200, marginTop: 20, padding: 15, fontSize: 18}} id="outlined-basic" label="Outlined" variant="outlined"
              placeholder={'اكتب هنا ...'}
              />
{!this.props.state.sendingTicket && 
            <Button onClick={()=>this.props.send(this.input.current.value)} variant="contained" style={{width: '92%', height: 40, backgroundColor: '#000', color: '#fff', fontWeight: 'bold', fontSize: 18,marginTop: 10}}>
                ارسل
            </Button>
    } 
{this.props.state.sendingTicket && 

<Button variant="contained" style={{width: '92%', height: 40, backgroundColor: 'lightgray', color: '#fff', fontWeight: 'bold', fontSize: 18,marginTop: 10}}>
جار الارسال .. 
</Button>
}



           {/* <AudioRecorderView/> */}

         </AnimatedDiv>

           
        )
    }

}