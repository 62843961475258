
import * as React from "react";
import { Component } from "react";
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import Brightness1Icon from '@material-ui/icons/Brightness1';
import RemoveIcon from '@material-ui/icons/Remove';
import { Button, TextareaAutosize, TextField } from "@material-ui/core";
import styled, { keyframes } from 'styled-components';

import { slideInUp } from 'react-animations'
import ChatBubble from 'react-chat-bubble';
const slideInUpAnimation = keyframes`${slideInUp}`;

const AnimatedDiv = styled.div`
  animation: 0.2s ${slideInUpAnimation};
`;


export default class TicketTextView extends Component {


    constructor(props) {
        super(props);
        this.state = {
            isRecording: false,
            blobURL: '',
            isBlocked: false,
          }

    }
  



    
    render() {



        return (

         <AnimatedDiv style={{position:'fixed', top:0, width:'100%', height: '100%', direction:'rtl', textAlign:'center',backgroundColor: '#fff', zIndex:99999}}>
        <div style={{marginTop: 20, fontSize: 18, direction:'rtl'}}>تفاصيل طلب المراجعة لرحلة رقم {this.props.orderNo}</div>
           
           <ChatBubble
            messages = {this.props.messages}
           />

         </AnimatedDiv>

           
        )
    }

}